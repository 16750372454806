<template>
    <div class="orders-details-tabs__support-container help-center tab-content">
        <header class="orders-details-tabs__tab-header">
            <p class="orders-details-tabs__tab-description">
                <img
                    src="@/assets/img/svg/information.svg"
                    alt="Deadline"
                    width="20px"
                    height="20px"
                    class="mdi"
                >
                Here is the list of all support tickets open with regards to this project.
                You can create a new support ticket directly from this page.
            </p>
        </header>

        <div class="orders-details-tabs__support-tickets">
            <button
                class="btn-base btn-main"
                @click="scrollToSupportForm()"
            >
                Create a support ticket
            </button>

            <support-card
                v-for="(ticket, index) in order.tickets"
                :key="ticket.ticketid"
                :data="ticket"
                :index="index"
            />
        </div>
        <div
            v-if="showCreateTicketForm"
            class="create-support-ticket"
        >
            <header
                id="create-ticket"
                class="orders-details-tabs__create"
            >
                <h2 class="orders-details-tabs__tab-title">
                    New Ticket
                </h2>
            </header>
            <support-form
                :prop-order-id="order.orderid"
                :sw_id="sw_id"
                @createTicket="onCreateTicket"
            />
        </div>
    </div>
</template>

<script>
import { scroller } from 'vue-scrollto/src/scrollTo'
import SupportForm from '@/components/support/SupportTicketForm.vue'
import SupportCard from '@/components/account/SupportCard/SupportCard';

export default {
    name: 'Tickets',
    components: {
        SupportCard,
        SupportForm
    },
    props: {
        order: {
            type: Object
        },
        swId: {
            type: Number
        }
    },
    data() {
        return {
            showCreateTicketForm: false
        }
    },
    methods: {
        scrollToSupportForm() {
            this.showCreateTicketForm = !this.showCreateTicketForm
            setTimeout(() => {
                const supportForm = document.querySelector('#create-ticket')
                if (supportForm) {
                    const ScrollTo = scroller();
                    ScrollTo(supportForm, {
                        duration: 600,
                        offset: -85
                    });
                }
            }, 500);
        },
        onCreateTicket(ticket) {
            this.order.tickets.push(ticket)
            this.order.counters.tickets_count += 1
            this.showCreateTicketForm = !this.showCreateTicketForm
        }
    }
}
</script>
