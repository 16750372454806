<template>
    <div
        class="support-form support-form--auth"
    >
        <div
            v-if="!propOrderId"
            class="support-form_header"
        >
            <p>
                {{ writer.auth ? `Hi ${capitalizeFirstLetter(writer.firstname)}, how can we help?` : 'Support Request' }}
            </p>
            <span
                class="btn-base btn-main"
                @click="openModal('confirm-modal')"
            >Clear</span>
        </div>

        <div class="support-form_body">
            <div
                v-if="!chk_phone_bypass"
                class="row"
            >
                <div class="support-form_field">
                    <form-switch
                        id="chk_callback"
                        v-model="chk_callback"
                    />
                    <span class="form-switch_text">I would like a phone call from a support officer</span>
                </div>
            </div>

            <reference-order
                v-if="writer.auth"
                label="I have a problem with a specific order"
                :errors="validationMessage($v.linked_to_order)"
                @change.native="$v.linked_to_order.$touch()"
                @blur.native="$v.linked_to_order.$touch()"
            />
        </div>

        <form-radio-group
            v-if="types"
            :classes="'support-form_group'"
            :errors="validationMessage($v.type)"
            @change.native="$v.type.$touch()"
            @blur.native="$v.type.$touch()"
        >
            <template v-if="types.msgtype">
                <div
                    v-for="(item, index) in types.msgtype.list"
                    :key="index"
                    class="support-form_group-item"
                >
                    <form-input-radio
                        v-model="type"
                        :text="item.text"
                        :data="item.value"
                        :is-checked="type === item.value"
                        name="type"
                        :field.sync="type"
                    />
                </div>
            </template>
        </form-radio-group>

        <div
            v-if="type === `complaint`"
            class="complaint_row"
        >
            <div class="col-6 complaint_group">
                <h2>What is the problem?</h2>
                <form-radio-group
                    :errors="validationMessage($v.problem)"
                    @change.native="$v.problem.$touch()"
                    @blur.native="$v.problem.$touch()"
                >
                    <template v-if="types.msgcategory">
                        <div
                            v-for="(item, index) in types.msgcategory.list"
                            :key="index"
                            class="complaint_group-item"
                        >
                            <form-input-radio
                                v-model="problem"
                                :text="item.text"
                                :data="item.value"
                                :is-checked="problem === item.value"
                                name="problem"
                                :field.sync="problem"
                            />
                        </div>
                    </template>
                </form-radio-group>
            </div>

            <div class="col-6 complaint_group">
                <h2>How would you like it resolved?</h2>
                <form-radio-group
                    :errors="validationMessage($v.resolution)"
                    @change.native="$v.resolution.$touch()"
                    @blur.native="$v.resolution.$touch()"
                >
                    <div
                        v-for="(item, index) in types.msgresolution.list"
                        :key="index"
                        class="complaint_group-item"
                    >
                        <form-input-radio
                            v-model="resolution"
                            :text="item.text"
                            :data="item.value"
                            :is-checked="resolution === item.value"
                            name="resolution"
                            :field.sync="resolution"
                        />
                    </div>
                </form-radio-group>
            </div>

            <p class="col-12">
                Before submitting your complaint, please make sure you have read and accept the
                <a
                    :href="'/info/terms'"
                    target="_blank"
                >agreements</a>.
            </p>
        </div>

        <div
            v-if="type"
            class="support-form_message"
        >
            <div class="row">
                <form-input
                    v-model="$v.subject.$model"
                    legend="Subject"
                    type="text"
                    name="subject"
                    :field.sync="subject"
                    :errors="validationMessage($v.subject)"
                    @input.native="$v.subject.$touch()"
                    @blur.native="$v.subject.$touch()"
                />
            </div>

            <div class="row">
                <form-textarea
                    v-model="$v.message.$model"
                    legend="Message"
                    type="text"
                    name="problem"
                    :field.sync="message"
                    :errors="validationMessage($v.message)"
                    @input.native="$v.message.$touch()"
                    @blur.native="$v.message.$touch()"
                />
            </div>
        </div>

        <div class="support-form_footer">
            <span
                class="btn-base btn-main"
                @click.prevent="validateForm()"
            >
                <button-loader v-if="submitLoading" />
                Submit a request
            </span>
        </div>

        <confirm-modal ref="confirm-modal" />
    </div>
</template>

<script>
// Helpers
import { scroller } from 'vue-scrollto/src/scrollTo'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import Api from '@/helpers/api/index.js';
// Validation
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/support/Rules'
import { formMessages } from '@/validation/support/Messages'

// Icons

// Components
import ButtonLoader from '@/components/common/ButtonLoader';
import ConfirmModal from '@/components/modals/ConfirmModal';
import ReferenceOrder from '@/components/support/ReferenceOrder';

// Vuex types
import { ACTION_TICKET_SUBMIT } from '@/store/modules/support/action-types';
import { CLEAR_FORM } from '@/store/modules/support/mutation-types';

// vuex bindings
import { mapSupportFields } from '@/store/modules/support';

const {
    mapMutations: mapSupportMutations,
    mapActions: mapSupportActions
} = createNamespacedHelpers('support');

export default {
    components: {
        ButtonLoader,
        ConfirmModal,
        ReferenceOrder
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    props: {
        propOrderId: {
            type: String
        }
    },
    validations: {
        ...validationRules
    },
    data() {
        return {
            chk_phone_bypass: false,
            submitLoading: false,
            phone_call: false,
            phoneValidationStatus: false,
            types: null
        }
    },
    computed: {
        ...mapGetters('writer', {
            writer: 'getterMainWriterInfo'
        }),
        ...mapSupportFields([
            'form_data.clientid',
            'form_data.data_client.email',
            'form_data.data_client.firstname',
            'form_data.data_client.lastname',
            'form_data.data_support.chk_callback',
            'form_data.data_support.chk_linked',
            'form_data.data_support.linked_to_order',
            'form_data.data_support.type',
            'form_data.data_support.problem',
            'form_data.data_support.resolution',
            'form_data.data_support.subject',
            'form_data.data_support.message'
        ])
    },
    watch: {
        client() {
            this.updateDataFromClient()
        }
    },
    created() {
        this.getTypes()
    },
    mounted() {
        this.updateDataFromClient()
    },
    methods: {
        ...mapSupportMutations({
            clearForm: CLEAR_FORM
        }),
        ...mapSupportActions({
            submitTicket: ACTION_TICKET_SUBMIT
        }),
        async getTypes() {
            const { data: types } = await Api.get('/api/support/get-types-for-html')
            this.types = types
        },
        validationMessage: validationMessage(formMessages),
        async openModal() {
            const modalTitle = 'Clear Form'
            const modalText = 'Are you sure you want to delete all data from the order form?'
            if (await this.$root.$confirm(modalTitle, modalText, { color: 'red' })) {
                this.clearForm()
                this.clearPhone()
                eventBus.$emit('clearPhone')
                this.$v.$reset()
            }
        },
        capitalizeFirstLetter(value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        async validateForm() {
            await this.$v.$touch()
            if (!this.$v.$invalid) {
                this.submit()
            } else {
                const firstErrorElement = document.querySelector('.error-font-color');
                const firstScrollTo = scroller()
                firstScrollTo(firstErrorElement, 200, { offset: -90 })
                this.validate()
            }
        },
        async submit() {
            this.submitLoading = true
            try {
                const data = await this.submitTicket()
                eventBus.$emit('showSnackBar', 'A support ticket has been created. You can view all tickets in your account (Support Tickets).', 'success');
                // eventBus.$emit('getTotalList')
                this.$emit('createTicket', data)
                this.chk_phone_bypass = false
                const firstScrollTo = scroller()
                firstScrollTo(document.body, 200)
                this.updateDataFromClient()
                this.$v.$reset()
                this.$router.push('/cp/tickets')
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.submitLoading = false
            }
        },
        updateDataFromClient() {
            if (this.writer.auth) {
                this.clientid = this.writer.id
                this.firstname = this.writer.firstname
                this.lastname = this.writer.lastname
                this.email = this.writer.email
            }
            if (this.propOrderId) {
                this.linked_to_order = this.propOrderId
                this.chk_linked = true
            }
        }
    }
}
</script>
