<template>
    <div class="last-message">
        <div class="last-message_header">
            <div class="last-message_date">
                Last message: <span>{{ lastMessage.created_at | moment_from }} ago</span>
            </div>
            <div class="last-message_from">
                From
                <avatar
                    class="last-message_avatar"
                    :avatar-url="lastMessage.author.avatar_url"
                    :nickname="lastMessage.author.firstname"
                    :user="lastMessage.author.type"
                />
                {{ lastMessage.author.firstname }} ({{ lastMessage.author.type }})
            </div>
        </div>
        <div class="last-message_text">
            « {{ lastMessage.details }} »
        </div>
    </div>
</template>

<script>
import filtersMixin from '@/mixins/filtersMixin.js'
import Avatar from '../../common/Avatar';

export default {
    components: {
        Avatar
    },
    mixins: [filtersMixin],
    props: {
        lastMessage: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
    .last-message{
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #abb1bb;
        &_header {
            display: flex;
            align-items: center;
            color: #abb1bb;
            @media all and (max-width: 476px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        &_date{
            margin-right: 30px;
            @media all and (max-width: 476px) {
                margin-bottom: 10px;
            }
        }
        &_from{
            display: flex;
            align-items: center;
        }
        &_avatar {
            margin-left: 15px;
            margin-right: 10px;
            .avatar-img{
                width: 20px;
                height: 20px;
            }
        }
        &_text{
            margin-top: 10px;
        }
    }
</style>
