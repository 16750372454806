<template>
    <div class="chat">
        <div
            ref="chat_wrapper"
            class="chat-wrapper"
        >
            <div>
                <div
                    v-for="(messageItem, message_index) in tmpMessages"
                    :key="message_index"
                    ref="chat_item"
                    class="chat_item"
                    :class="{ 'chat_item__support': messageItem.author && messageItem.author.type === 'Admin' }"
                >
                    <div
                        v-if="messageItem.author && messageItem.author.type === 'Admin'"
                        class="chat_item__avatar"
                    >
                        <avatar
                            :avatar-url="messageItem.author.avatar_url"
                            :nickname="messageItem.author.firstname"
                            :user="messageItem.author.type"
                        />
                    </div>
                    <div class="chat_item__inside">
                        <div class="chat_item__content-header">
                            <span
                                v-if="messageItem.author && messageItem.author.type === 'Admin'"
                                class="chat_item__content-header__name"
                            >
                                <span v-if="messageItem.author.firstname">
                                    {{ messageItem.author.firstname }}
                                    <template v-if="messageItem.author.type && messageItem.author.id">
                                        {{ messageItem.author.type }} {{ messageItem.author.id }}
                                    </template>
                                </span>
                            </span>
                        </div>
                        <div
                            class="chat_item__content"
                        >
                            <span
                                v-if="messageItem.created_at"
                                class="chat_item__content-header__time"
                            >
                                <span>{{ messageItem.created_at | moment_from }} ago</span>
                                <span>
                                    {{ messageItem.created_at | moment_short_text }}
                                </span>
                            </span>
                            <div
                                class="chat_item__content-text"
                                v-html="splitByCharacters(messageItem.details)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MessageForm
            :is-loading="isLoading"
            :clear-form="clearForm"
            :show-resolve-btn="status === 'OPEN'"
            @typing="typingListener"
            @sendMessage="sendMessage"
            @formCleared="clearForm = false"
            @closeTicket="$emit('closeTicket')"
        >
            <template #typing>
                <span>{{ typingMessage }}</span>
                <div
                    v-if="typingMessage"
                    class="dot-flashing"
                />
            </template>
        </MessageForm>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
// event bus
import filtersMixin from '@/mixins/filtersMixin.js'

// vuex bindings
import MessageForm from '@/components/common/MessageForm';
import Avatar from '../../common/Avatar';

export default {
    components: {
        Avatar,
        MessageForm
    },
    mixins: [
        filtersMixin
    ],
    props: {
        messages: {
            type: Array,
            required: true
        },
        ticketid: {
            type: String,
            required: true
        },
        status: {
            type: String,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tmpMessages: [],
            typingMessage: null,
            typing: false,
            clearForm: false
        }
    },
    computed: {
        ...mapGetters('writer', [
            'getterMainWriterInfo'
        ])
    },
    watch: {
        typing(newVal) {
            this.$socket.emit('support:typing', {
                typing: newVal,
                ticketid: this.ticketid
            })
        },
        'messages.length': function () {
            this.tmpMessages = [...this.messages]
            this.$nextTick(() => {
                this.scrollToEnd()
            })
        }
    },
    created() {
        this.tmpMessages = [...this.messages]
        this.chatWSConnect()
    },
    mounted() {
        this.$emit('markAsRead')
        this.scrollToEnd()
    },
    beforeDestroy() {
        this.$socket.emit('support:unsubscribe', this.ticketid)
    },
    methods: {
        scrollToEnd() {
            this.$refs.chat_wrapper.scrollTo({ top: 999999, behavior: 'smooth' })
        },
        sendMessage({ message }) {
            if (message.length === 0) { return }
            this.$emit('sendMessage', { message })
            this.clearForm = true
        },
        splitByCharacters(text) {
            const splitString = text.split('\n').join('<br>')
            return splitString
        },
        chatWSConnect() {
            this.$socket.emit('support:subscribe', { ticketid: this.ticketid, hash: this.getHash })
            this.$socket.on('support:newMessage', (data) => {
                if (data.author.id === this.getterMainWriterInfo.id) return
                this.tmpMessages = [...this.tmpMessages, data]
                this.$nextTick(() => {
                    this.scrollToEnd()
                })
            })
            this.$socket.on('support:typing', (data) => {
                if (data.role === 'Writer' || data.role === 'Lead') return
                this.typingMessage = data.message
            })
            this.$socket.on('disconnect', () => {
                this.chatWSConnect()
            });
        },
        typingListener(value) {
            this.typing = value
        }
    }
}
</script>

<style scoped lang="scss">
.chat-wrapper {
    min-height: 300px;
    max-height: 500px;
    overflow-y: scroll;
    margin-bottom: 10px;
}

.chat_item__content--writer {
    background: #f0f0f0;
}
</style>
