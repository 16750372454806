<template>
    <div class="ticket-card">
        <div class="ticket-card_index">
            {{ index+1 }}
        </div>
        <div class="ticket-card_data">
            <header class="ticket-card_header">
                <div class="ticket-card_type">
                    <span v-if="data.long_names.type">{{ data.long_names.type }}</span>
                    <span v-if="data.long_names.category">{{ data.long_names.type }}</span>
                    <span v-if="data.long_names.resolution">{{ data.long_names.resolution }}</span>
                </div>
                <div
                    class="ticket-card_status"
                    :class="`ticket-card_status--${data.status_support}`"
                >
                    {{ data.status_support }}
                </div>
            </header>

            <div class="ticket-card_details">
                <p class="ticket-card_title">
                    «{{ data.subject }}»
                </p>
                <div class="ticket-card_additional">
                    <div class="ticket-card_date">
                        <img
                            src="@/assets/img/svg/clock.svg"
                            alt="Deadline"
                            width="20px"
                            height="20px"
                            class="mdi"
                        >
                        <span>{{ data.created_at | moment_from }} ago</span>
                    </div>
                    <span class="ticket-card_bell">
                        <img
                            v-if="bubbles > 0"
                            src="@/assets/img/svg/bell.svg"
                            alt="bell"
                            width="28px"
                            height="28px"
                            class="mdi"
                        >
                        <img
                            v-else
                            src="@/assets/img/svg/bell-colorless.svg"
                            alt="bell"
                            width="28px"
                            height="28px"
                            class="mdi"
                        >
                        <span
                            v-if="bubbles > 0"
                            class="bubble--unread bubble--unread-header"
                        >
                            {{ bubbles }}
                        </span>
                    </span>
                </div>
            </div>

            <div class="ticket-card_main-info">
                <div class="ticket-card_detail">
                    <div class="ticket-card_detail-title">
                        Ticket ID
                    </div>
                    <div class="ticket-card_detail-data">
                        <img
                            :src="require(`@/assets/img/svg/clipboard.svg`)"
                            alt="Ticket ID"
                            class="ticket-card_detail-img"
                        >
                        <div class="ticket-card_detail-counter">
                            {{ data.ticketid }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="data.orderid"
                    class="ticket-card_detail"
                >
                    <div class="ticket-card_detail-title">
                        Order ID
                    </div>
                    <div class="ticket-card_detail-data">
                        <img
                            :src="require(`@/assets/img/svg/clipboard.svg`)"
                            alt="Ticket ID"
                            class="ticket-card_detail-img"
                        >
                        <div class="ticket-card_detail-counter">
                            {{ data.orderid }}
                        </div>
                    </div>
                </div>
                <div class="ticket-card_detail">
                    <div class="ticket-card_detail-title">
                        Comments
                    </div>
                    <div class="ticket-card_detail-data">
                        <img
                            :src="require(`@/assets/img/svg/message-square.svg`)"
                            alt="Ticket ID"
                            class="ticket-card_detail-img"
                        >
                        <div class="ticket-card_detail-counter">
                            {{ data.messages.length }}
                        </div>
                    </div>
                </div>
            </div>

            <transition name="last-message-animation">
                <SupportCardLastMessage
                    v-if="!showChat"
                    :last-message="data.last_message"
                />
            </transition>

            <transition name="chat-animation">
                <SupportCardChat
                    v-if="showChat"
                    :messages="messages"
                    :status="data.status_support"
                    :is-loading="isMessageInSending"
                    :ticketid="data.ticketid"
                    @markAsRead="readTicket"
                    @sendMessage="sendMessage"
                    @closeTicket="closeTicket"
                />
            </transition>

            <div
                class="ticket-card_toggle"
                @click="showChat = !showChat"
            >
                <chevron-up v-if="showChat" />
                <chevron-down v-else />
                {{ !showChat ? 'Show' : 'Hide' }} chat
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ChevronUp from 'mdi-vue/ChevronUp'
import ChevronDown from 'mdi-vue/ChevronDown'
import SupportCardChat from '@/components/account/SupportCard/SupportCardChat.vue'
import SupportCardLastMessage from '@/components/account/SupportCard/SupportCardLastMessage.vue'

import filtersMixin from '@/mixins/filtersMixin.js'
import {
    markAsReadTicket, sendMessage, closeTicket, fetchMessages
} from '@/services/support/supportApi.js'
import { eventBus } from '@/helpers/event-bus/'

export default {
    components: {
        SupportCardChat,
        SupportCardLastMessage,
        ChevronUp,
        ChevronDown
    },
    mixins: [filtersMixin],
    props: {
        data: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            showChat: false,
            isMessageInSending: false,
            messages: []
        }
    },
    computed: {
        ...mapGetters('writer', [
            'getterIsAuth'
        ]),
        bubbles() {
            return this.data.counters?.unread_messages_count || this.data.unread_messages_count
        }
    },
    watch: {
        async showChat(newVal) {
            if (newVal) {
                const data = await fetchMessages({ ticketid: this.data.ticketid }, this.getterIsAuth)
                this.messages = [...data]
            }
        }
    },
    created() {
        this.messages = [...this.data.messages]
    },
    methods: {
        async sendMessage({ message }) {
            try {
                this.isMessageInSending = true
                const data = await sendMessage({
                    ticketid: this.data.ticketid,
                    message
                }, this.getterIsAuth)
                this.messages.push(data)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.isMessageInSending = false
            }
        },
        async readTicket() {
            if (this.bubbles === 0) return
            try {
                await markAsReadTicket({ ticketid: this.data.ticketid }, this.getterIsAuth)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        async closeTicket() {
            try {
                await closeTicket({ id: this.data.ticketid }, this.getterIsAuth)
                this.data.status_support = 'CLOSED'
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        }
    }
}
</script>

<style lang="scss">
    .ticket-card{
        display: flex;
        margin-bottom: 20px;
        border: 1px solid #e7ecf4;
        border-radius: 4px;
        position: relative;
        box-shadow: 0px 15px 45px rgba(0, 0, 0, 0.1);
        &_index {
            width: 40px;
            background: #1F2939;
            color: #fff;
            font-weight: 400;
            text-align: center;
            padding-top: 20px;
            @media all and (max-width: 768px) {
                display: none;
            }
        }
        &_data {
            padding: 20px;
            padding-bottom: 0;
            width: 100%;
        }
        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
        &_type {
            display: flex;
            flex-wrap: wrap;
            span {
                background: rgba(12, 45, 72, 0.1);
                color: rgb(12, 45, 72);
                padding: 6px 12px;
                border-radius: 4px;
                margin-right: 20px;
                font-size: 14px;
                font-weight: 500;
                position: relative;
                margin-bottom: 10px;
                &::after {
                    content: ">";
                    margin-right: 5px;
                    position: absolute;
                    right: -18px;
                }
                &:last-child{
                    margin-right: 0;
                    &::after {
                        display: none;
                    }
                }
            }
        }
        &_status {
            padding: 6px 12px;
            border-radius: 4px;
            line-height: 1;
            font-size: 14px;
            font-weight: 500;
            margin-top: -10px;
            &--OPEN {
                background: rgba(89, 199, 77, 0.1);
                color: #59C74D;
                font-weight: bold;
            }
            &--CLOSED {
                background: rgba(255, 105, 12, 0.1);
                color: #ff690c;
                font-weight: bold;
            }
        }
        &_details{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media all and (max-width: 576px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        &_additional{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media all and (max-width: 576px) {
                margin-top: 10px;
            }
        }
        &_title{
            font-size: 18px;
            font-weight: 500;
        }
        &_date{
            display: flex;
            align-items: center;
            margin-right: 20px;
            img {
                margin-right: 5px;
            }
        }
        &_toggle{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;
            margin-top: 10px;
            margin-left: -20px;
            width: calc(100% + 40px);
            transition: .3s all;
            cursor: pointer;
            &:hover {
                background: rgba(12, 45, 72, 0.05);
            }
        }
        &_main-info{
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            @media all and (max-width: 576px) {
                flex-direction: column;
            }
        }
        &_detail{
            margin-right: 20px;
            @media all and (max-width: 576px) {
                margin-right: 0;
                margin-bottom: 10px;
            }
            &-data {
                display: flex;
                align-items: center;
                img {
                    margin-right: 5px;
                }
            }
            &-title {
                display: flex;
                color: #afb2b9;
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 8px;
            }
        }
    }
    .last-message-animation-enter,
    .last-message-animation-leave-to {
        opacity: 0;
        transform: rotateX(-90deg);
        transition: opacity .3s linear, transform 0.3s linear;
    }

    .last-message-animation-enter-to,
    .last-message-animation-leave {
        opacity: 1;
        transform: rotateX(0);
        transition: opacity .3s linear, transform 0.3s linear;
    }
    .chat-animation-enter-active {
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
    }
    .chat-animation-leave-active {
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    .chat-animation-enter-to, .chat-animation-leave {
        max-height: 500px;
        overflow: hidden;
    }
    .chat-animation-enter, .chat-animation-leave-to {
        overflow: hidden;
        max-height: 0;
    }
</style>
